<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">Sign Up</h1>
				<p class="text-lg">Get started with your Social Media Automation Journey on YetuShare. We'll send your login credentials to your email.</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">
			<template #title>
				<h5 class="font-semibold text-center">Register With</h5>
			</template>
			
			<p class="text-center my-25 font-semibold text-muted">Email</p>
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'name',
						{ rules: [{ required: true, message: 'Please input your name!' }] },
						]"
						placeholder="Name"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] },
						]"
						placeholder="Email"
					>
					</a-input>
				</a-form-item>
				
				<a-form-item class="mb-10">
					<a-checkbox
						v-decorator="[
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						},
						]"
					>
						By Signing Up, I agree the <a href="#" class="font-bold text-dark">Terms and Conditions</a>
					</a-checkbox>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						SIGN UP
					</a-button>
				</a-form-item>
			</a-form>

			<vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

			<p class="font-semibold text-muted text-center" v-if="error">{{ error }}</p>

			<p class="font-semibold text-muted text-center">Already have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign In</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>

	import {userSignupJSON} from '../services/v1/services';

	import {saveCurrentToken, saveUserInfo, isUserLoggedIn, getUserInfo} from '../services/v1/data_methods';

	export default ({
		mounted() {
			console.log('Sign Up page mounted.', this.$route.query.package_id);
			// Check if user is logged in.
			if ( this.$store.getters.isUserLoggedIn ) {
				// Redirect to dashboard page.
				this.$router.push('/dashboard');
			}
		},
		data() {
			return {
				error: null,
				loading: false,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			async handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields(async(err, values) => {
					if ( !err ) {
						console.log('Received values of form: ', values) ;

						this.loading = true;

						this.error = null;


						// optional url parameter package_id

						let params = {}

						const package_id = this.$route.query.package_id;

						if (package_id) {
							params.package_id = package_id;
						}

						const item = {
							email: values.email,
							name: values.name,
						};

						const res = await userSignupJSON(item, params);

						this.loading = false;

						console.log(res.data);

						console.log('........')

						if (res.Ok) {
							if(res.data.status) {
								this.$message.success('Sign up successful. Please check your email for login credentials.');
								this.$router.push('/sign-in');
							}

							else{
								this.$message.error('Sign up failed. Please try again.');
								// this.error = res.data.msg;

								if(res.data.msg.indexOf('duplicate') > -1) {
									this.error = 'Email already exists. Please use another email.';
								}
								else {
									this.error = res.data.msg;
								}
							}
							
						}
						else {
							this.$message.error('Sign up failed. Please try again.');
							this.error = res.status.detail;
						}
					} 
				});
			},
		},
	})

</script>

<style lang="scss">
</style>